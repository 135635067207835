<template>
    <ion-header class="ion-no-border">
      <ion-toolbar color="primary">
        <div class="container">
            <ion-toolbar color="transparent">
                <ion-buttons slot="start">
                    <ion-menu-button menu="mainMenu"></ion-menu-button>
                </ion-buttons>
                <img v-if="!openSearch" src="/assets/logo-light.png" @click="$router.push({ path: `/` })" alt="Tienda Online" class="logo">

                <div id="autocomplete" :class="openSearch ? 'searchbar' : 'hidden'"/>

                <ion-buttons slot="end">
                    <ion-button @click="openCloseSearch">
                        <ion-icon v-if="!openSearch"  :icon="searchOutline" slot="icon-only"></ion-icon>
                        <ion-icon v-if="openSearch"  :icon="closeOutline" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-button @click="openCart" class="notification-container">
                        <ion-icon :icon="cartOutline" slot="icon-only"></ion-icon>
                        <ion-badge class="notification-badge" color="tertiary">{{ cart.length }}</ion-badge>
                    </ion-button>
                    <!--<ion-button @click="$router.push({ path: `/login-register` })">
                        <ion-icon  :icon="personOutline" slot="icon-only"></ion-icon>
                    </ion-button>-->
                </ion-buttons>
            </ion-toolbar>
        </div>
      </ion-toolbar>
    </ion-header>
</template>

<script>
import { defineComponent } from 'vue';
import { personOutline, cartOutline, searchOutline, closeOutline } from 'ionicons/icons';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton, menuController, IonBadge} from '@ionic/vue';
import { mapState } from 'vuex';

import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
const searchClient = algoliasearch(
  'FA3H1DJ7LN',
  'bbfcaa08028f7d34230d3313c8717f62'
);

import '@algolia/autocomplete-theme-classic';

export default defineComponent({
  name: 'Header',
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonMenuButton,
    IonBadge,
  },
  data() {
    return {
      openSearch: false,
      searchText: '',
    }
  },
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    openCart() {
      menuController.open('cartMenu');
    },

    buscar(){
      this.$router.push({ path: `/search`, query: { s: encodeURI(this.searchText) } });
      this.openSearch = false;
      this.searchText = '';
    },

    openCloseSearch(){
      this.openSearch = !this.openSearch;
      this.searchText = '';
    },
  },
  mounted(){
    autocomplete({
      container: '#autocomplete',
      detachedMediaQuery: 'none',
      placeholder: 'Buscar...',
      onSubmit: ({state}) => this.$router.push({ path: `/search`, query: { s: state.query } }),
      getSources() {
        return [
          {
            sourceId: 'querySuggestions',
            getItemInputValue: ({ item }) => item.query,
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'shop_products_query_suggestions',
                    query,
                    params: {
                      hitsPerPage: 6,
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return item.query
              },
            },
          },
        ];
      },
    });
  },
  setup() {
    return {
      personOutline, cartOutline, searchOutline, closeOutline
    }
  }
});
</script>

<style scoped>
    .logo{
        width: 128px;
        height: auto;
        cursor: pointer;
        margin-left: 0.25rem;
        vertical-align: text-bottom;
    }

    .hidden{
      display: none;
    }

    .searchbar{

    }

    .suggestions{

    }

    
</style>