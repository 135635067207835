<template>
  <ion-footer>
    <ion-toolbar color="tertiary">
      <div class="container">
        <ion-grid>
          <ion-row class="sm-section">
            <ion-col size="12" size-lg="6">
              <div class="box">
                <img src="/assets/logo-light.png"  @click="$router.push({ path: `/` })" alt="Tienda Online" class="logo">
                <p style="margin: 1rem 0; opacity: 0.75">Somos empresa emprendedora especializada en la manufactura y distribución de productos de Acero Inoxidable.</p>
                <ion-buttons>
                  <ion-button @click="openExternal('https://www.facebook.com/novoinox/')">
                    <ion-icon  :icon="logoFacebook" slot="icon-only"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </div>
            </ion-col>

            <ion-col size="12" size-lg="3">
              <div class="box">
                <h5>Información</h5>
                <ul class="footer-links">
                  <li><router-link :to="'/contact'">Contacto</router-link></li>
                  <li><router-link :to="'/about-us'">Sobre Nosotros</router-link></li>
                  <li><router-link :to="'/legal'">Aviso Legal</router-link></li>
                  <li><router-link :to="'/privacity'">Privacidad</router-link></li>
                  <li><router-link :to="'/cookies'">Cookies</router-link></li>
                </ul>
              </div>
            </ion-col>

            <ion-col size="12" size-lg="3">
              <div class="box">
                <h5>Mi Cuenta</h5>
                <ul class="footer-links">
                  <!--<li><router-link :to="'/'">Mis Pedidos</router-link></li>-->
                  <li><router-link :to="'/purchase-conditions'">Condiciones de Compra</router-link></li>
                  <li><router-link :to="'/returns'">Devoluciones</router-link></li>
                  <li><router-link :to="'/faqs'">FAQ's</router-link></li>
                  <li><router-link :to="'/sitemap'">Mapa de Sitio</router-link></li>
                </ul>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="box">
            <ion-col size="12" size-lg="6">
              <h6>&copy; {{ year }} novoinox.com</h6>
            </ion-col>
            <ion-col size="12" size-lg="6">
              <h6 class="poweredby">Powered By <a href="https://evolbe.systems"><strong>Evolbe</strong></a></h6>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonFooter, IonToolbar, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import { logoFacebook, logoInstagram, logoPinterest, logoTwitter } from 'ionicons/icons';

export default defineComponent({
  name: 'Footer',
  components: {
    IonFooter,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    openExternal(link) {
      window.open(link);
    },
  },
  setup() {
    return {
      logoFacebook, logoInstagram, logoPinterest, logoTwitter
    }
  }
});
</script>

<style scoped>
  .logo{
      width: 192px;
      height: auto;
      cursor: pointer;
  }

  a{
    color: var(--ion-color-dark-contrast) !important;
  }

  .footer-links{
    margin: 1rem 0;
    padding: 0;
    list-style: none;
  }

  .footer-links li a{
    opacity: 0.75;
  }

  .footer-links li a:hover, .footer-links li a:active{
    opacity: 1 !important;
  }

  .poweredby{
    padding-top: 0.5rem;
  }

  @media (min-width: 960px) {
    .poweredby{
      text-align: right;
      padding-top: 0rem;
    }
  }
</style>