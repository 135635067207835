
import { defineComponent } from 'vue';
import { IonFooter, IonToolbar, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import { logoFacebook, logoInstagram, logoPinterest, logoTwitter } from 'ionicons/icons';

export default defineComponent({
  name: 'Footer',
  components: {
    IonFooter,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    openExternal(link) {
      window.open(link);
    },
  },
  setup() {
    return {
      logoFacebook, logoInstagram, logoPinterest, logoTwitter
    }
  }
});
